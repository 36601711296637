/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label,global-require */
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import useI18n from '../utils/useI18n';
import SEO from '../components/seo/SEO';

const Privacy = ({ pageContext: { switchLang }, location, data }) => {
  const { t } = useI18n();
  const PrivacyContent = data.privacy.html;
  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={t('privacy.meta.title')}
        description={t('privacy.meta.description')}
        keywords={t('privacy.meta.keywords')}
        pathname={location.pathname}
        webpage
      />
      <div
        className="o-wrapper u-margin-top-large u-margin-bottom"
        dangerouslySetInnerHTML={{ __html: PrivacyContent }}
      />
    </Layout>
  );
};

export const query = graphql`
  query PrivacyPageQuery {
    privacy: htmlFragment(name: { eq: "privacy" }) {
      html
    }
  }
`;

export default Privacy;
